import breadcrumbComp from "../../common/breadcrumb-comp";
//import commonService from "./common-api-service"
import EncryptUtility from "../../utility/js/encrypt-utility";
//import Utility from "../../../shared/utility.js";
import DisplayTextData from "../../common/display-text-data.vue";
import materialMovementService from "@/components/material-movement/js/material-movement-service";

export default {
  props: {
    showLPNDetails: Boolean,
    lpnNum: Number

  },
  data() {
    return {
      issueId: "",
      backSlash: true,
      changeBugStatusDialog: false,
      showDeleteDialog: false,
      customPage: true,
      file: "",
      files: "",
      fileList: [],
      noFiles: true,
      refresh: true,
      dragging: false,
      images: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchBugs: "",
      showLPNDetails: false,
      issueTitle: "",
      issueDescription: "",
      uploadFileName: "",
      url: "",
      isFormValid: false,
      bugData: [],
      filesTable: [],
      updatedHistoryTable: [],
      totalRecords: 0,
      max150Rule: [(v) => (v || "").length <= 150 || "Max Length of 150 character", (v) => !!v || "Field is required"],
      max1000Rule: [(v) => (v || "").length <= 1000 || "Max Length of 1000 character", (v) => !!v || "Field is required"],
      fileRules: [
        (files) => !files || !files.some((file) => file.size > 10e5) || "File size should be less than 10 MB!",
        (v) => !!v || "Field is required",
      ],
      lpnDetailsHeaders: [
        { text: 'Part Number', align: 'start', value: 'PartNumber', class: 'primary customwhite--text', },
        { text: 'Warehouse', value: 'Warehouse', class: 'primary customwhite--text', align: 'start' },
        { text: 'Qty', value: 'Quantity', class: 'primary customwhite--text', align: 'start' },
      ],
      lpnDetails: [],

    };


  },
  async mounted() {
    let obj = {
      lpn: this.lpnNum,
      user_id: parseInt(this.userId),
      transferred: 0,
    };
    this.lpnDetails = await materialMovementService.getLPNContents("post", obj, false)
  },

  methods: {
    // Fucntion to get Status Text

    //Reset Function
    resetFunction() {
      this.url = "",
        this.searchBugs = "";
      this.uploadFileName = "";
      this.issueTitle = "";
      this.issueDescription = "";
      this.totalRecords = 0;
      this.this.$refs.newIssueForm.resetValidation();
      this.showLPNDetails = false;
      this.getUserIssueDetails();
    },



    //Close the popup
    closeDialog() {
      this.showLPNDetails = false;
      this.lpnDetails = []
      this.$emit("closeLPN", this.showLPNDetails)
    },

  },

  components: {
    breadcrumbComp,
    DisplayTextData
  },
};
