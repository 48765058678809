<template>
  <div class="mb-7">
    <v-app-bar elevation="2" style="z-index:1 !important; position: fixed;" dense class="mt-13 mx-7" fixed>
      <v-app-bar-nav-icon text>
        <v-btn icon small @click="goToHome()" color="secondary"><v-icon> mdi-home </v-icon></v-btn>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="pa-0 mt-0">
        <v-breadcrumbs class="pa-0 ma-0">
          <template>
            <v-breadcrumbs-item class="primarytext--text" disabled>
              {{ mainPage }}
            </v-breadcrumbs-item>
            <v-breadcrumbs-item small v-if="backSlash"> / </v-breadcrumbs-item>
            <v-breadcrumbs-item v-if="backSlashParent" class="primarytext--text">
              <router-link active-class="active" :to="'/' + subPageUrl" exact><a class="nav-link primarytext--text"> {{
                subPage }}</a></router-link>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item class="primarytext--text" v-else-if="customPage">
              <router-link active-class="active" :to="'/' + customSubPageUrl" exact><a class="nav-link primarytext--text">
                  {{
                    customSubPage
                  }}</a></router-link>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item class="primarytext--text" v-else>
              <router-link active-class="active" :to="'/' + pageUrl" exact><a class="nav-link primarytext--text"> {{
                subPage
              }}</a></router-link>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item class="primarytext--text hidden-sm-and-down" small v-if="backSlashParent"> /
            </v-breadcrumbs-item>
            <v-breadcrumbs-item class="primarytext--text">
              <router-link v-if="backSlashParent" active-class="active" :to="'/' + pageUrl" exact><a class="nav-link primarytext--text"> {{
                appPageName
              }}</a></router-link>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dense outlined class="mr-1 pa-0" @click="saveDataProp()" title="Save" v-if="printer" :disabled="disableSave" small>
        <v-icon color="green" dark small>mdi-content-save</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="primarytext" @click="onClickShip" title="Ready To Ship" v-if="showReadyToShip">
        <v-icon color="primarytext">mdi mdi-truck</v-icon>
      </v-btn>
      <v-btn v-if="dropLPN" class="mr-1 px-1 pa-0 activeCheck" dense small outlined :title="lpnDrop" @click="onClickDropLPN" color="customwhite">
        <v-icon small>mdi-download-outline</v-icon> {{ lpnDrop }}
      </v-btn>
      <v-btn v-if="lpnStatus" class="mr-1 px-1 pa-0 activeCheck" :disabled="!lpnNum" dense small outlined :title="lpnText" @click="onClickShowLPN">
        {{ lpnText }} <v-icon small></v-icon>
      </v-btn>

      <v-btn v-if="redirectTo" class="mr-1 px-1 pa-0" dense small outlined :title="title" color="primarytext" @click="redirectToPage">
        {{ title }} <v-icon small> mdi-arrow-right</v-icon>
      </v-btn>
      <v-btn v-if="savePart" class="mr-1 pa-0" dense small outlined title="Save" color="primarytext" @click="partSave()" :disabled="savePartDisable">
        <v-icon small>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-if="showUpdate" class="mr-1 pa-0" dense small outlined title="Update" color="primarytext" @click="updateFunction()">
        <v-icon small>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-if="updateSO" class="mr-1 pa-0" dense small outlined title="Update" color="primarytext" @click="soUpdate()">
        <v-icon small>mdi-content-save</v-icon>
      </v-btn>
      <v-btn v-if="showPartChange" @click="onClickChangePart" class="pa-0 my-0 float-left" dense small outlined title="Change Part Number"
        color="primarytext"> <v-icon small> mdi mdi-swap-horizontal </v-icon>
      </v-btn>
      <v-btn v-if="updatePart" class="mr-1 pa-0" dense small outlined title="Update" color="primarytext" @click="partUpdate()">
        <v-icon small>mdi-content-save</v-icon>
      </v-btn>

      <v-btn v-if="saveRole" :disabled="disableSaveRole && pageUrl == 'app-role-edit'" class="mr-1 pa-0" dense small outlined title="Save"
        color="primarytext" @click="saveRoleDetails()">
        <v-icon small>mdi-content-save</v-icon>
      </v-btn>
      <v-btn title="Download Printer Utility" @click="downloadUtilityProp()" v-if="printer" dense outlined class="mr-1 pa-0" small>
        <v-icon small color="primarytext">mdi-arrow-down-bold-circle-outline</v-icon>
      </v-btn>
      <v-btn dense small outlined class="mr-1 pa-0" @click="removeDataProp()" title="Remove Printer Details" v-if="printer">
        <v-icon color="red" small>mdi-close-circle</v-icon>
      </v-btn>
      <!-- if show refresh is true then it will open refresh dialog box -->
      <v-btn class="mr-1 pa-0" dense small outlined color="primarytext" v-if="showRefreshDialog && !addReset" @click="refreshDialog = true"
        title="Reset">
        <v-icon color="primarytext" small>mdi-refresh</v-icon>
      </v-btn>
      <!--Reset Draft  -->
      <v-btn class="mr-2 pa-2  f-bold hidden-sm-and-down" dense small outlined color="error" v-if="showRefreshDialog && addReset"
        @click="refreshDialog = true" title="Restart Draft">
        RESTART DRAFT
      </v-btn>
      <v-btn class="hidden-md-and-up mr-1" dense small outlined color="error" v-if="showRefreshDialog && addReset" @click="refreshDialog = true"
        title="Restart Draft">
        <v-icon small>mdi mdi-restart</v-icon>
      </v-btn>
      <v-btn class=" mr-1" dense small outlined color="primary" v-if="refreshDetails" @click="resetFunctionProp" title="Refresh">
        <v-icon small>mdi mdi-restart</v-icon>
      </v-btn>
      <!-- If show Refresh dialog is false then refresh button will resresh -->
      <v-btn v-else-if="refresh && !showRefreshDialog && !addReset" icon small @click="resetFunctionProp()" title="Reset">
        <v-icon color="primarytext">mdi-refresh</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="primarytext" v-else-if="refreshOutlined" title="Reset" @click="resetFunctionProp()">
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon small @click="addFav()" title="Add To Favorites" v-if="!showAdd && !back && !hideFav && !customBack">
        <v-icon color="secondary">mdi mdi-star-outline</v-icon>
      </v-btn>
      <v-btn icon small @click="addFav()" title="Remove From Favorites" v-if="showAdd && !back && !hideFav && !customBack">
        <v-icon color="secondary">mdi-star</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onUserActionHistory()" title="User Action History" v-if="actionHistory">
        <v-icon color="primarytext" small>mdi-account-cog-outline</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onUserHistory()" title="User Login History" v-if="loginHistory">
        <v-icon color="primarytext" small>mdi mdi-account-clock-outline</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onSalesOrderHistory()" title="Sales Order History" v-if="soHistory">
        <v-icon color="primarytext" small>mdi mdi-clipboard-text-clock</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onSalesOrderHistory()" title="Purchase Order History" v-if="poHistory">
        <v-icon color="primarytext" small>mdi mdi-clipboard-text-clock</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onAssignedUserList()" title="Assigned Users" v-if="assignedUserList">
        <v-icon color="primarytext" small>mdi mdi-account-multiple-outline</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="primarytext" @click="onEditClickRedirect" title="Edit" v-if="showEditRedirect">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="secondary" @click="onAddBookmark" title="Add Bookmark" v-if="!bookmark && showBookMark">
        <v-icon small>mdi mdi-bookmark-outline</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="secondary" @click="onRemoveBookmark" title="Remove BookMark"
        v-if="bookmark && showBookMark">
        <v-icon color="secondary">mdi mdi-bookmark</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="#D8A000" @click="onSOHold" title="Hold Order" v-if="!hold && showHold">
        <v-icon color="#D8A000" small>mdi mdi-pause-box-outline</v-icon>
      </v-btn>
      <v-btn class="mr-1 pa-0" dense small outlined color="primarytext" @click="onSORelease" title="Release Order" v-if="hold && showHold">
        <v-icon color="primarytext">mdi mdi-play-outline</v-icon>
      </v-btn>


      <v-btn class="mr-1 pa-0" dense small outlined color="error" @click="onClickDelete" :title="showHold ? 'Cancel' : 'Delete'" v-if="showDelete">
        <v-icon v-if="showHold" small>mdi mdi-cancel</v-icon>
        <v-icon v-else small>mdi-delete</v-icon>
      </v-btn>
      <v-btn dense outlined class="mr-1 pa-0" small @click="onEditClick()" title="Edit" v-if="showEdit">
        <v-icon color="primarytext" small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn v-if="back" class="mr-1 pa-0" dense small outlined title="Back" color="primarytext" @click="$router.go(-1)">
        <v-icon small> mdi-arrow-left </v-icon>
      </v-btn>
      <v-btn v-if="customBack" class="mr-1 pa-0" dense small outlined title="Back" color="primarytext" @click="customRedirect">
        <v-icon small> mdi-arrow-left </v-icon>
      </v-btn>

      <v-btn icon small @click="onClickReportIssue" title="Report Issue">
        <v-icon color="primary">mdi mdi-comment-alert</v-icon>
      </v-btn>
      <!-- hamburger -->
      <span v-if="showHamBuger" class="hidden-md-and-up">
        <v-menu offset-y :rounded="1" :close-on-content-click="false" :nudge-width="100">

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon elevation="0" class="pa-0 ma-0" v-bind="attrs" v-on="on">
              <v-icon color="secondary">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list v-if="showActionsList" :key="i" class="web-list py-3 float-right" width="150">
            <v-row>
              <v-col offset-sm="3" cols="12" sm="12" md="12" lg="6" class="px-5 py-1" v-for="item in showActionsList" :key="item" link>
                <v-text @click="clickEvents(item.Id)" class="float-left">
                  <v-icon color="secondary" small v-text="item.Icon"></v-icon>&nbsp;<v-text class="font-weight-bold secondary--text">
                    {{ item.FriendlyName }}</v-text>
                </v-text>
              </v-col>
            </v-row>
          </v-list>
        </v-menu>
      </span>
    </v-app-bar>
    <!-- Refresh Dialog -->
    <v-row justify="center">
      <v-col sm="12" md="6" lg="6">
        <v-dialog v-model="refreshDialog" max-width="500px">
          <v-card class="add-menu containerbox">
            <v-card-title class="text-subtitle-1 mx-auto">Are you sure you want to reset this item?</v-card-title>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>

              <template>
                <v-btn color="primarytext" outlined dense class="ma-2 btn-style" elevation="1" @click="resetFunctionProp">OK</v-btn>
                <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click=" refreshDialog = false">Cancel
                </v-btn>
              </template>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <FeedbackForm v-if="showFeedbackForm" :showFeedbackForm="showFeedbackForm" :pageName="page" :url="url" @closeFeedback="closeFeedbackDialog" />
    <ViewLPNDetails v-if="showLPNDetails" :showLPNDetails="showLPNDetails" @closeLPN="closeLPNDialog" :lpnNum="lpnNum" />

  </div>
</template>

<script>
import { bus } from '../../main'
import FeedbackForm from './feedback-comp.vue';
import ViewLPNDetails from "./lpn-details-comp.vue";
export default {
  name: 'breadcrumbComp',
  props: {
    hideFav: Boolean,
    mainPage: String,
    subPage: String,
    pageName: String,
    addReset: Boolean,
    showHamBuger: Boolean,
    refresh: Boolean,
    appPageName: String,
    showUpdate: Boolean,
    pageUrl: String,
    refreshDetails: Boolean,
    updateSO: Boolean,
    customSubPageUrl: String,
    customSubPage: String,
    customPage: String,
    redirectTo: Boolean,
    title: String,
    poHistory: Boolean,
    customUrl: String,
    showAdd: Boolean,
    showRemove: Boolean,
    printer: Boolean,
    saveRole: Boolean,
    backSlash: Boolean,
    resetFunction: Function,
    saveData: Function,
    downloadUtility: Function,
    removeData: Function,
    backSlashParent: String,
    showPartChange: Boolean,
    subPageUrl: String,
    showDelete: Boolean,
    showRefreshDialog: Boolean,
    refreshOutlined: Boolean,
    showEdit: Boolean,
    back: Boolean,
    customBack: Boolean,
    disableSave: Boolean,
    showEditRedirect: Boolean,
    redirectPage: Function,
    bookmark: Boolean,
    addBookmark: Function,
    removeBookmark: Function,
    showBookMark: Boolean,
    showHold: Boolean,
    hold: Boolean,
    soHold: Function,
    loginHistory: Boolean,
    actionHistory: Boolean,
    soHistory: Boolean,
    assignedUserList: Boolean,
    userListAssigned: Function,
    savePart: Boolean,
    savePartDisable: Boolean,
    updatePart: Boolean,
    changePartNum: Function,
    showActionsList: Array,
    saveRoleDetail: Function,
    updateRoleDetail: Function,
    lpnStatus: Boolean,
    dropLPN: Boolean,
    lpnText: String,
    lpnDrop: String,
    lpnNum: Number,
    showReadyToShip: Boolean,
  },
  data: () => ({
    refreshDialog: false,
    bookmark: false,
    hold: false,
    disableSaveRole: true,
    showFeedbackForm: false,
    showLPNDetails: false,
    page: "",
    url: "",


  }),
  created() {
    bus.$on('bookmark', (data) => {
      this.bookmark = data.bookmark
    }),
      bus.$on('hold', (data) => {
        this.hold = data
      })
  },
  mounted() {
  },
  beforeUpdate() {
    this.setFeedbackPage()
  },



  methods: {
    clickEvents(item) {
      switch (item) {
        case "SO-BookMark":
          this.$emit('addBookmark');
          break;
        case "SO-BookMark-Remove":
          this.$emit('removeBookmark');
          break;
        case "SO-Hold":
          this.onSOHold();
          break;
        case "SO-Release":
          this.onSORelease();
          break;
        case "SO-Delete":
          this.onClickDelete();
          break;
        case "SO-Reset":
          this.refreshDialog = true;
          break;
        case "PO-Reset":
          this.refreshDialog = true;
          break;
        case "SO-Edit":
          this.$emit('redirectPage');
          break;
        case "PO-Edit":
          this.$emit('redirectPage');
          break;
        case "SO-History":
          this.$emit('salesOrderHistoryClicked');
          break;
        case "PO-History":
          this.$emit('salesOrderHistoryClicked');
          break;
        case "PO-BookMark":
          this.$emit('addBookmark');
          break;
        case "PO-BookMark-Remove":
          this.$emit('removeBookmark');
          break;
        case "PO-Hold":
          this.onSOHold();
          break;
        case "PO-Release":
          this.onSORelease();
          break;
        case "PO-Delete":
          this.onClickDelete();
          break;
        default:
          break;
      }
    },
    resetFunctionProp() {
      this.resetFunction();
      this.refreshDialog = false
    },
    goToHome() {
      this.$router.push('/home');
    },
    customRedirect() {
      this.$router.push(this.customUrl);
    },
    soUpdate() {
      this.$emit("updateSODetails");
    },
    updateFunction() {
      this.$emit("updateFunction");
    },
    partSave() {
      this.$emit("savePartDetails");
    },
    partUpdate() {
      this.$emit("updatePartDetails");
    },
    onClickChangePart() {
      this.$emit("changePartNum");
    },

    saveRoleDetails() {
      this.$emit("saveRoleDetail");
    },

    saveDataProp() {
      this.saveData();
    },
    downloadUtilityProp() {
      this.downloadUtility();
    },
    addFav() {
      this.$emit('addFav');
    },
    removeDataProp() {
      this.removeData();
    },
    onEditClick() {
      this.disableSaveRole = false;
      this.$emit('editClicked');
    },
    onClickDelete() {
      this.$emit('deleteClicked');
    },
    onEditClickRedirect() {
      this.$emit('redirectPage');
    },
    onAddBookmark() {
      this.$emit('addBookmark');
    },
    onRemoveBookmark() {
      this.$emit('removeBookmark');
    },
    onSOHold() {
      this.$emit('hold');
    },
    onSORelease() {
      this.$emit('release');
    },
    onUserHistory() {
      this.$emit('userHistoryClicked');
    },
    onUserActionHistory() {
      this.$emit('userActionHistoryClicked');
    },
    onSalesOrderHistory() {
      this.$emit('salesOrderHistoryClicked');
    },
    onAssignedUserList() {
      this.$emit('assignedUserClicked');
    },
    onClickShip() {
      this.$emit('readyToShip');
    },
    onClickReportIssue() {
      this.showFeedbackForm = true
    },
    onClickShowLPN() {
      this.showLPNDetails = true
    },
    onClickDropLPN() {
      this.$emit('onClickDropLPN');
    },
    closeFeedbackDialog() {
      this.showFeedbackForm = false
    },
    closeLPNDialog() {
      this.showLPNDetails = false
    },

    setFeedbackPage() {
      if (this.customPage) {
        this.page = this.customSubPage,
          this.url = this.customSubPageUrl
      }
      else if (this.backSlashParent) {
        this.page = this.appPageName
          this.url = this.pageUrl
      }
      else {
        this.page = this.subPage
        this.url = this.pageUrl
      }
    },
    redirectToPage() {
      if (this.title == "Redirect To Put Away") {
        this.$router.push("/put-away");
      }
      else {
        this.$router.push("/move");
      }

    }
  },
  components: {
    FeedbackForm,
    ViewLPNDetails
  },
};
</script>
